.App {
  font-family: 'Roboto', sans-serif;;
  text-align: center;
  background-color: #ebebeb
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #282c34;
  color:  white;
  padding: 12px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
  height: 5.5rem;
}

.navbar-light .navbar-toggler {
  background-color: white;
}

.mr-auto, .mx-auto {
  background-color: #343a40;
}

button {
  margin-bottom: 15px;
  margin-top: 15px;
}

p {
  padding: 0 5px 0 5px;
}

.input-group-prepend {
  margin-left: 15px;
}

.form-control {
  margin-right: 15px;
}

.boeyimg {
  border-radius: 50%;
}

footer {
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: 10px;
  padding-top: 30px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
}

#footerdiv {
  background-color: #282c34;
  color: white;
  font-size: 16px;
  margin: 0px -16px 0px -16px;

}